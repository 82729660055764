import React, { useRef, useState, useEffect } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { useLoader } from '@react-three/fiber';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { OrbitControls } from '@react-three/drei';
import { Container, Row, Col, Image, Carousel, Card, Button } from 'react-bootstrap';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import FlyInText from './FlyInText';

// Scene Component
function Scene() {
  const meshRef = useRef();
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  useFrame(({ mouse }) => {
    setMousePosition({ x: mouse.x, y: mouse.y });
    if (meshRef.current) {
      meshRef.current.rotation.x -= (mouse.y + meshRef.current.rotation.x) * 0.004;
      meshRef.current.rotation.y += (mouse.x - meshRef.current.rotation.y) * 0.004;
    }
  });

  const gltf = useLoader(GLTFLoader, '/homeHub.glb');
  const model = gltf.scene;
  model.position.z = 0;

  return <primitive object={model} ref={meshRef} />;
}

// ImageWithTextOverlay Component
const ImageWithTextOverlay = () => (
  <div className="container_random">
    <div className="image-container_random">
      <img src="./HomeHubLivingRoom4.png" alt="HomeHub" className="image_random" />
      <div className="overlay_random">
        <Container>
          <Row className='mt-3'>
            <h1 className="overlay-text_random">Smart Automation <br /> for Every Room</h1>
            <p className="overlay-text_random">Featuring a 200° field of view, the Heliostat Smart Hub manages multiple areas at once, adapting to your movements and preferences. Automate your environment to match your activities, making home technology intuitive and responsive.</p>
          </Row>
        </Container>
      </div>
    </div>
  </div>
);

// WelcomeSection Component
const WelcomeSection = () => (
  <Container>
    <Row>
      <Col className='p-5 d-flex align-items-center' sm={6}>
        <div>
          <h1>Welcome to Next-Generation Home Automation</h1>
          <p>Step into the future with a smart hub that revolutionizes home management. Effortlessly enhance daily living with seamless automation of lighting, audio, and more. Embrace a new level of convenience and sophistication in home control.</p>
        </div>
      </Col>
      <Col sm={6}>
        <Canvas style={{ height: '30rem' }} camera={{ position: [0, 0, 80], fov: 30 }}>
          <ambientLight intensity={Math.PI / 2} />
          <OrbitControls enableZoom={false} enablePan={false} />
          <spotLight position={[100, 100, 10]} angle={0.15} penumbra={1} decay={0} intensity={Math.PI} />
          <pointLight position={[-100, -100, -10]} decay={0} intensity={Math.PI} />
          <Scene />
        </Canvas>
      </Col>
    </Row>
  </Container>
);

// SmarterLivingSection Component
const SmarterLivingSection = () => (
  <div className='text-area'>
    <h1>Your Gateway to Smarter Living!</h1>
    <p>Transform your house into a home that’s not just smart, but truly intelligent. With cutting-edge sensors seamlessly integrating into your living space, revolutionize how you interact with your environment.</p>
    <p>Crafted with precision, the Heliostat Smart Sensor ensure reliability and longevity. Combining sleek aesthetics with intuitive functionality, it represent a commitment to excellence in both design and performance.</p>
    <p>Experience the future of smart living. Elevate your home to new heights of intelligence, comfort, and security. Welcome to a world where innovation meets tranquility.</p>
  </div>
);

const PrivacyFirstSection = () => {
  const [animationTriggered, setAnimationTriggered] = useState(false);
  const [animationComplete, setAnimationComplete] = useState(false);
  const [imageOpacity, setImageOpacity] = useState(1); // Start fully visible
  const imageContainerRef = useRef(null); // Reference to the image container

  // Function to trigger the image blending animation
  const startAnimation = () => {
    let startTime = Date.now();
    const animationDuration = 1500;

    const animate = () => {
      const currentTime = Date.now();
      const elapsedTime = currentTime - startTime;
      const progress = Math.min(elapsedTime / animationDuration, 1); // Ensure the value stays between 0 and 1
      setImageOpacity(1 - progress); // Linear interpolation to transition from 1 to 0 opacity

      if (progress < 1) {
        requestAnimationFrame(animate); // Continue the animation
      } else {
        setAnimationComplete(true); // Mark the animation as complete
      }
    };

    animate();
  };

  // Setting up the IntersectionObserver to detect when the image is in the center
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && !animationTriggered && !animationComplete) {
          setAnimationTriggered(true);
          startAnimation(); // Start the animation when the element is in the center
        }
      },
      {
        root: null, // Observe relative to the viewport
        threshold: 0.5, // Trigger when 50% of the image is in view
      }
    );

    if (imageContainerRef.current) {
      observer.observe(imageContainerRef.current);
    }

    return () => {
      if (imageContainerRef.current) {
        observer.unobserve(imageContainerRef.current);
      }
    };
  }, [animationTriggered, animationComplete]);

  return (
    <div className='bg-black'>
      <Row className="align-items-center" style={{ height: '100vh', margin: 0 }}>
        <Col sm={5} className='p-0 m-0 d-flex align-items-center'>
          <div className='text-area-black'>
            <div className='text-area-text ps-5 ms-5'>
              <FlyInText>
                <h1 className="">Privacy First,<br />Innovation Always</h1>
                <p className="">Prioritizing privacy, the Heliostat Smart Hub processes all data locally, ensuring your information remains secure. Data stays within the device, meeting the highest standards of data protection and confidentiality.</p>

              </FlyInText>
            </div>
          </div>
        </Col>
        <Col
          sm={7}
          className="p-0 m-0 bg-black d-flex align-items-center justify-content-center"
          style={{ height: '100vh' }}
        >
          <div
            ref={imageContainerRef}
            style={{
              position: 'relative',
              width: '100%',
              height: '100%',
              overflow: 'hidden',
            }}
          >
            {/* Transparent image that fades out based on the animation */}
            <img
              src="./NoneTransparent.png"
              alt="Transparent"
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                position: 'absolute',
                top: 0,
                left: 0,
                opacity: imageOpacity,
                transition: 'opacity 0.3s ease',
              }}
            />
            {/* Non-transparent image that fades in based on the animation */}
            <img
              src="./Transparent.png"
              alt="NoneTransparent"
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                position: 'absolute',
                top: 0,
                left: 0,
                opacity: 1 - imageOpacity,
              }}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};


// ImageSection Component
const ImageSection = () => (
  <div className='bg-black'>

    <div className="image-container">
      <Image src="./TopView-FOV.png" alt="HomeHub" className='room-image' fluid />
    </div>
    <div className='text-area text-white'>
      <h1>Your Gateway to Smarter Living!</h1>
      <p>Transform your house into a home that’s not just smart, but truly intelligent. With cutting-edge sensors seamlessly integrating into your living space, revolutionize how you interact with your environment.</p>
      <p>Crafted with precision, the Heliostat Smart Sensor ensure reliability and longevity. Combining sleek aesthetics with intuitive functionality, it represent a commitment to excellence in both design and performance.</p>
      <p>Experience the future of smart living. Elevate your home to new heights of intelligence, comfort, and security. Welcome to a world where innovation meets tranquility.</p>
    </div>
  </div>
);

// HomeHubCard Component
const HomeHubCard = () => (
  <Card className="card mb-4 border-0" style={{ margin: '5%' }}>
    <Carousel>
      <Carousel.Item>
        <img src="./HomeHubWood.png" style={{ width: '100%' }} alt="HomeHub" />
        <Carousel.Caption>
          <h3>Privacy First, Innovation Always</h3>
          <p>Discover the Future of Home Intelligence with Heliostat.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img src="./HomeHubStone.png" style={{ width: '100%' }} alt="HomeHub" />
        <Carousel.Caption>
          <h3>Smart Sensors, Smarter Homes</h3>
          <p>Experience Seamless Integration with Heliostat.</p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
    <Card.Body>
      <Card.Title>Home Hub</Card.Title>
      <Card.Text>
        Our cutting-edge smart home sensor blends seamlessly into your living space, revolutionizing the way you interact with your environment.
      </Card.Text>
      <Button variant="primary">More info</Button>
    </Card.Body>
  </Card>
);

// Main App Component
const App = () => (
  <div className="App-header">
    <Carousel>
      <Carousel.Item>
        <img src="./HomeHubWood.png" style={{ width: '100%' }} alt="HomeHub" />
        <Carousel.Caption>
          <h3>Privacy First, Innovation Always</h3>
          <p>Discover the Future of Home Intelligence with Heliostat.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img src="./HomeHubStone.png" style={{ width: '100%' }} alt="HomeHub" />
        <Carousel.Caption>
          <h3>Smart Sensors, Smarter Homes</h3>
          <p>Experience Seamless Integration with Heliostat.</p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>

    <WelcomeSection className="m-5" />
    <ImageWithTextOverlay />
    <SmarterLivingSection />
    <ImageSection />
    <PrivacyFirstSection />
    <div className="image-container">
      <Image src="./DarkRoomDark.png" alt="HomeHub" className='room-image' fluid />
    </div>
    <HomeHubCard />
  </div>
);

export default App;
